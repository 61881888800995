import React from 'react';
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import * as styles from './Hero.module.scss';
import { graphql, useStaticQuery } from 'gatsby';


export interface HeroProps {
    image: any
    children?: any;
}

const Hero = ({ image, children }: HeroProps) => {

    return <div className={styles.container}>
        <GatsbyImage
        className={styles.image}
        alt=""
        image={image}
      /> 
      <div className={styles.overlay}>
          {children}
      </div>
    </div>
}

export default Hero;
