import { graphql } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Layout from "../Layout/index";
import Hero from '../components/HeroPassthrough';
import SEO from '../components/SEO';

const BlogPost = ({ data }) => {
  return (
    <Layout>
      <SEO title={data.wpPost.title}/>
        <Hero image={data.wpPost.featuredImage.node.localFile.childImageSharp.gatsbyImageData}></Hero>
      <Container className="mt-3">
        <Row>
          <Col>
            <h1>{data.wpPost.title}</h1>
            <small>by {data.wpPost.author.node.name}</small>
          </Col>
        </Row>
        <Row>
            <div dangerouslySetInnerHTML={{ __html: data.wpPost.content }}></div>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      excerpt
      content

      author {
        node {
          name
          nicename
        }
      }

      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }


    }
  }
`;

export default BlogPost;
